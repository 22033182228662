import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container, Row, Col } from "react-bootstrap"
import slcLogo from "../images/slc-logo.png"
import lmsLogo from "../images/lms-logo.png"
import styled from "styled-components"
class Solutions extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const Title = styled.h2`
      color: #333333 !important;
      &:hover {
        color: #74ffc2 !important;
      }
    `
    return (
      <Layout>
        <SEO
          title="Solutions"
          description="Prolacs has a new generation IoT solutions for laundries designed with industry 4.0 inspiration."
        />
        <Container>
          <div className="prolacs-divider-title">
            <br></br>
            <br></br>
            <h1>Solutions</h1>
          </div>
          <Row
            className="prolacs-solutions-first-row"
            style={{ height: "53vh" }}
          >
            <Col
              sm
              data-aos="fade-up"
              data-aos-duration="4000"
              data-aos-once="false"
            >
              <Link to="/solutions/smart-laundromat-control">
                <img
                  className="solutions-logo"
                  src={slcLogo}
                  alt="prolacs smart laundromat control"
                />
                <Title>Smart Laundromat Control</Title>
              </Link>
              <p>
                SLC is the professional self-service laundromats solution that
                lets you control your businesses end to end. It has a modular
                and scalable structure suitable for every brand of machines. It
                increases the profitability of your business with modules such
                as consumption management, campaing management and overall
                equipment efficiency.
              </p>
            </Col>
            <Col
              sm
              data-aos="fade-up"
              data-aos-duration="4000"
              data-aos-once="false"
            >
              <Link to="/solutions/laundry-management-system">
                <img
                  className="solutions-logo"
                  style={{ marginBottom: "40px" }}
                  src={lmsLogo}
                  alt="prolacs laundry management system"
                />
                <Title>Laundry Management System</Title>
              </Link>
              <p>
                LMS is the professional laundry solution that lets you control
                your businesses end to end. It has a modular and scalable
                structure suitable for every business. It increases the
                profitability of your business with modules such as consumption
                management, personel productivity and overal equipment
                efficiency.{" "}
              </p>
            </Col>
          </Row>
        </Container>
      </Layout>
    )
  }
}

export default Solutions
